import { render, staticRenderFns } from "./DailyTxFees.vue?vue&type=template&id=bffc7706&scoped=true&"
import script from "./DailyTxFees.vue?vue&type=script&lang=js&"
export * from "./DailyTxFees.vue?vue&type=script&lang=js&"
import style0 from "./DailyTxFees.vue?vue&type=style&index=0&id=bffc7706&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bffc7706",
  null
  
)

export default component.exports