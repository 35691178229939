var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history_card"},[_c('h2',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.$t("view_home.latest_blocks"))+" "),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'blocks' }}},[_vm._v(" All blocks ")])],1),_c('div',{staticClass:"homeblocklist animlist"},[_c('f-data-table',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"items":_vm.dItems,"disable-infinite-scroll":!_vm.dHasNext,"loading":_vm.cLoading,"fixed-header":"","f-card-off":"","mobile-view":""},scopedSlots:_vm._u([{key:"column-block",fn:function(ref){
var value = ref.value;
var column = ref.column;
var col = ref.col;
return [(column)?_c('div',[_c('div',{staticClass:"row no-collapse no-vert-col-padding mobile"},[_c('p',{staticClass:"col-4 f-row-label "},[_vm._v(" "+_vm._s(column.label)+" "),_c('icon',{attrs:{"data":require("@/assets/svg/angle-right.svg"),"color":"#B6B6B6","width":"3px","height":"6px"}})],1),_c('div',{staticClass:"col col_block"},[_c('router-link',{attrs:{"to":{
                                    name: 'block-detail',
                                    params: { id: value }
                                },"title":value}},[_vm._v(_vm._s(value))])],1)]),_c('div',{staticClass:"row-2 no-collapse no-vert-col-padding desktop row-svg"},[_c('div',{staticClass:"svg_block"},[_c('Blocks')],1),_c('div',[_c('p',{staticClass:"col-5 f-row-label"},[_vm._v(" "+_vm._s(column.label)+" "),_c('icon',{attrs:{"data":require("@/assets/svg/angle-right.svg"),"color":"#B6B6B6","width":"3px","height":"6px"}})],1),_c('div',{staticClass:"col col_block "},[_c('router-link',{attrs:{"to":{
                                        name: 'block-detail',
                                        params: { id: value }
                                    },"title":value}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(value))+" ")])],1)])])]):[_c('div',{staticClass:"animlist_label"},[_vm._v(" "+_vm._s(col.label)+" › ")]),_c('span',[_vm._v(_vm._s(value)+" ")])]]}},{key:"column-age",fn:function(ref){
                                    var value = ref.value;
                                    var column = ref.column;
                                    var col = ref.col;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label "},[_vm._v(" "+_vm._s(column.label)+" ")]),_c('div',{staticClass:"col col_age"},[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{ includeSeconds: true }}})],1)]):[_c('div',{staticClass:"animlist_label"},[_vm._v(" "+_vm._s(col.label)+" ")]),_c('span',[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":5,"converter-options":{ includeSeconds: true }}})],1)]]}},{key:"column-transaction_count",fn:function(ref){
                                    var value = ref.value;
                                    var column = ref.column;
                                    var col = ref.col;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-4 f-row-label "},[_vm._v(" "+_vm._s(column.label)+" ")]),_c('div',{staticClass:"col col_tx"},[_vm._v(_vm._s(value))])]):[_c('div',{staticClass:"animlist_label"},[_vm._v(" "+_vm._s(col.label)+" ")]),_c('span',[_vm._v(_vm._s(value))])]]}}])},'f-data-table',Object.assign({}, _vm.$attrs, _vm.$props),false))],1)])}
var staticRenderFns = []

export { render, staticRenderFns }