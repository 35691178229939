var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"history_card"},[_c('h2',{staticClass:"h3"},[_vm._v(" "+_vm._s(_vm.$t("view_home.latest_transactions"))+" "),_c('router-link',{staticClass:"link",attrs:{"to":{ name: 'transactions' }}},[_vm._v(" "+_vm._s(_vm.$t("view_home.view_all_transactions"))+" ")])],1),_c('div',{staticClass:"hometransactionlist-dark"},[_c('transition',{attrs:{"enter-active-class":"fadelong-enter-active"}},[_c('f-data-table',_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}],staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.dColumns,"hidden-columns":_vm.hiddenColumns,"items":_vm.dItems,"loading":_vm.cLoading,"f-card-off":"","mobile-view":"","first-m-v-column-width":"5","fixed-header":""},scopedSlots:_vm._u([{key:"column-hash",fn:function(ref){
var value = ref.value;
var column = ref.column;
var col = ref.col;
return [(column)?_c('div',[_c('div',{staticClass:"row no-collapse no-vert-col-padding mobile"},[_c('p',{staticClass:"col-5 f-row-label"},[_vm._v(" "+_vm._s(column.label)+" "),_c('icon',{attrs:{"data":require("@/assets/svg/angle-right.svg"),"color":"#B6B6B6","width":"3px","height":"6px"}})],1),_c('div',{staticClass:"col col_hash"},[_c('router-link',{attrs:{"to":{
                                        name: 'transaction-detail',
                                        params: { id: value }
                                    },"title":value}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(value))+" ")])],1)]),_c('div',{staticClass:"row-2 no-collapse no-vert-col-padding desktop row-svg"},[_c('div',{staticClass:"svg_block"},[_c('Transactions')],1),_c('div',[_c('p',{staticClass:"col-5 f-row-label"},[_vm._v(" "+_vm._s(column.label)+" "),_c('icon',{attrs:{"data":require("@/assets/svg/angle-right.svg"),"color":"#B6B6B6","width":"3px","height":"6px"}})],1),_c('div',{staticClass:"col col_hash"},[_c('router-link',{attrs:{"to":{
                                            name: 'transaction-detail',
                                            params: { id: value }
                                        },"title":value}},[_vm._v(" "+_vm._s(_vm._f("formatHash")(value)))])],1)])])]):[_c('div',{staticClass:"animlist_label"},[_vm._v(" "+_vm._s(col.label)+" › ")]),_c('span',[_vm._v(" "+_vm._s(_vm._f("formatHash")(value))+" ")])]]}},{key:"column-timestamp",fn:function(ref){
                                        var value = ref.value;
                                        var column = ref.column;
                                        var col = ref.col;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(" "+_vm._s(column.label)+" ")]),_c('div',{staticClass:"col col_time"},[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{
                                    includeSeconds: true
                                }}})],1)]):[_c('div',{staticClass:"animlist_label"},[_vm._v(" "+_vm._s(col.label)+" ")]),_c('span',[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{
                                    includeSeconds: true
                                }}})],1)]]}},{key:"column-amount",fn:function(ref){
                                var value = ref.value;
                                var item = ref.item;
                                var column = ref.column;
                                var col = ref.col;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(" "+_vm._s(column.label)+" ")]),_c('div',{staticClass:"col col_amount"},[_vm._v(" "+_vm._s(value)+" ")])]):[_c('div',{staticClass:"animlist_label"},[_vm._v(" "+_vm._s(col.label)+" ")]),_c('span',[_vm._v(" "+_vm._s(value)+" ")])]]}}])},'f-data-table',Object.assign({}, _vm.$attrs, _vm.$props),false),[_c('Transactions')],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }