var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"address-asset-list-dt"},[(_vm.error)?_c('div',{staticClass:"query-error"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('f-data-table',{staticClass:"f-data-table-body-bg-color",attrs:{"columns":_vm.columns,"items":_vm.items,"loading":_vm.loading,"disable-infinite-scroll":!_vm.pageInfo.hasNext,"mobile-view":_vm.cMobileView,"infinite-scroll":"","first-m-v-column-width":"5"},on:{"fetch-more":_vm.fetchMore},scopedSlots:_vm._u([{key:"column-hash",fn:function(ref){
var value = ref.value;
var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                            name: 'transaction-detail',
                            params: { id: value }
                        },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{
                        name: 'transaction-detail',
                        params: { id: value }
                    },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-timestamp",fn:function(ref){
                    var value = ref.value;
                    var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{ includeSeconds: true }}})],1)]):[_c('timeago',{attrs:{"datetime":_vm.timestampToDate(value),"auto-update":1,"converter-options":{ includeSeconds: true }}})]]}},{key:"column-address",fn:function(ref){
                    var value = ref.value;
                    var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('router-link',{attrs:{"to":{
                            name: 'address-detail',
                            params: { id: value }
                        },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])],1)]):[_c('router-link',{attrs:{"to":{ name: 'address-detail', params: { id: value } },"title":value}},[_vm._v(_vm._s(_vm._f("formatHash")(value)))])]]}},{key:"column-amount",fn:function(ref){
                        var value = ref.value;
                        var item = ref.item;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[(_vm.addressCol)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.addressCol,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})]],2)]):[(_vm.addressCol)?[_c('f-account-transaction-amount',{attrs:{"address":_vm.addressCol,"from":_vm.getFrom(item),"to":_vm.getTo(item),"amount":value}})]:[_c('f-token-value',{attrs:{"value":value,"decimals":2,"use-placeholder":false,"no-currency":""}})]]]}},{key:"column-token",fn:function(ref){
                        var value = ref.value;
                        var column = ref.column;
return [(column)?_c('div',{staticClass:"row no-collapse no-vert-col-padding"},[_c('div',{staticClass:"col-5 f-row-label"},[_vm._v(_vm._s(column.label))]),_c('div',{staticClass:"col"},[_c('div',{staticClass:"smalltoken"},[_c('f-crypto-symbol',{attrs:{"token":value,"no-symbol":"","img-width":"16px","img-height":"16px"}}),(value.name && value.symbol)?_c('span',[_vm._v(_vm._s(value.name)+" ("+_vm._s(value.symbol)+")")]):_vm._e()],1)])]):[_c('div',{staticClass:"smalltoken"},[_c('f-crypto-symbol',{attrs:{"token":value,"no-symbol":"","img-width":"16px","img-height":"16px"}}),(value.name && value.symbol)?_c('span',[_vm._v(_vm._s(value.name)+" ("+_vm._s(value.symbol)+")")]):_vm._e()],1)]]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }