var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"f-address-detail narrow-container narrow-container-custom"},[_c('div',{staticClass:"f-subsection title"},[_c('h2',{staticClass:"h1"},[_vm._v(" Validator Staking ")]),_c('div',{staticClass:"mobile-btn"},[(!_vm.address)?_c('div',{class:!_vm.darkMode
                        ? 'btn-connect btn primary'
                        : 'btn-connect-dark btn primary',on:{"click":function($event){_vm.showModal = true}}},[_vm._v(" Connect Wallet ")]):_c('div',{class:!_vm.darkMode
                        ? 'btn-connect btn large primary'
                        : 'btn-connect-dark btn large primary'},[_c('div',{staticClass:"btn-connect__content"},[(_vm.isChainIdCorrect)?_c('div',[_c('Warning')],1):_vm._e(),_c('span',{staticClass:"btn-connect__content--text"},[_vm._v(_vm._s(_vm.shortAddress)+" ")]),_c('span',{staticClass:"btn-connect__content--icon",on:{"click":function($event){return _vm.disconnect()}}},[_c('icon',{attrs:{"data":require("@/assets/svg/exit.svg"),"width":"18","height":"18"}})],1)])])])]),_c('div',{staticClass:"connect-block"},[(!_vm.address)?_c('div',{class:!_vm.darkMode
                    ? 'btn-connect btn large primary'
                    : 'btn-connect-dark btn large primary',on:{"click":function($event){_vm.showModal = true}}},[_vm._v(" Connect Wallet ")]):_c('div',{class:!_vm.darkMode
                    ? 'btn-connect btn large primary'
                    : 'btn-connect-dark btn large primary'},[_c('div',{staticClass:"btn-connect__content"},[(_vm.isChainIdCorrect)?_c('div',[_c('Warning')],1):_vm._e(),_c('span',{staticClass:"btn-connect__content--text"},[_vm._v(_vm._s(_vm.shortAddress)+" ")]),_c('span',{staticClass:"btn-connect__content--icon",on:{"click":function($event){return _vm.disconnect()}}},[_c('icon',{attrs:{"data":require("@/assets/svg/exit.svg"),"width":"18","height":"18"}})],1)])])]),_c('div',{staticClass:"section"},[_c('WalletDelegationsList',{attrs:{"columns":_vm.columns,"items":_vm.filteredUserStakes,"loading":_vm.getLoadingStakes},on:{"unstake":_vm.openUnstake,"claim":_vm.openClaim,"connect":function($event){_vm.showModal = true}}})],1),_vm._m(0),_c('div',{staticClass:"section"},[_c('WalletDelegationsHistoryList',{attrs:{"loading":_vm.getLoadingStakes},on:{"unstake":_vm.openUnstake}})],1),(_vm.showModal)?_c('ConnectDialog',{on:{"close":function($event){_vm.showModal = false}}}):_vm._e(),(_vm.showUnstakeDialog)?_c('UnstakeDialog',{attrs:{"amount":_vm.unstakeItem.amount,"loading":_vm.getDialogLoading,"errorMsg":_vm.getErrorMsg},on:{"close":_vm.closeUntakeDialog,"action":_vm.unstake}}):_vm._e(),(_vm.showClaimDialog)?_c('ClaimDialog',{attrs:{"amount":_vm.claimItem.rewards,"loading":_vm.getDialogLoading,"errorMsg":_vm.getErrorMsg},on:{"close":_vm.closeClaimDialog,"claim":_vm.claim}}):_vm._e(),(_vm.showSuccessAlert)?_c('SuccessAlert',{attrs:{"successMsg":_vm.getSuccessMsg},on:{"close":_vm.closeSuccess}}):_vm._e(),(_vm.showErrorAlert)?_c('ErrorAlert',{attrs:{"errorMsg":_vm.getErrorMsg},on:{"close":_vm.closeError}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"h1"},[_vm._v(" History of unstaking ")])])}]

export { render, staticRenderFns }