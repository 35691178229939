var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"burnedftm"},[_c('FCard',{staticClass:"burnedftm_left"},[_c('p',{staticClass:"burnedftm_amount number"},[_vm._v(" "+_vm._s(_vm.cTotalBurned)+" "),_c('span',{staticClass:"burnedftm_ftm"},[_vm._v(_vm._s(_vm.symbol))])])]),(_vm.blocks.length > 0)?[_c('FCard',{staticClass:"burned"},_vm._l((_vm.blocks),function(block){return _c('div',{key:block.blockNumber,staticClass:"burnedftm_block",class:{
                    'burnedftm_block-animate': block.__animate__
                },attrs:{"data-blocknumber":_vm.formatHexToInt(block.blockNumber)}},[_c('div',{staticClass:"burnedftm_block_burned number"},[_c('div',{staticClass:"burnedftm_block_burned_icon"},[_c('div',{staticClass:"fsvgicon"},[_c('icon',{attrs:{"data":require("@/assets/svg/fire.svg"),"width":"24","height":"24","color":"#337AFE","aria-hidden":"true"}})],1),_c('div',{staticClass:"burnedftm_block_amount"},[_c('span',{staticClass:"burnedftm_block_amount_text"},[_vm._v("Amount (RWA)")]),_c('span',{staticClass:"burnedftm_block_amount_value"},[_vm._v(_vm._s(block.ftmValue))])])]),_c('div',{staticClass:"burnedftm_block_info"},[_c('span',{staticClass:"burnedftm_block_info_text"},[_vm._v(" Block")]),_c('router-link',{staticClass:"burnedftm_block_info_blocks",attrs:{"to":{
                                name: 'block-detail',
                                params: {
                                    id: _vm.formatHexToInt(block.blockNumber)
                                }
                            },"title":_vm.formatHexToInt(block.blockNumber)}},[_vm._v(" "+_vm._s(_vm.formatHexToInt(block.blockNumber))+" ")])],1)])])}),0)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }